// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back {
    color: #242424;
    text-decoration: none;
}

.back:hover {
    color: #717275;
    font-weight: bolder;
}

.project-desc {
    font-size: 1.25rem;
}

.project-title {
    font-size: 2.5rem;
}

.project-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 60%;
}

.project-link {
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    top: 0;
    z-index: 999;
    padding: 0;
}

.project-additional {
    text-align: center;
}

@media (max-width: 400px) {
    .Home-Content {
        padding: 0 10px;
    }
}


@media (min-width: 400px) 
    and (max-width: 600px) {
    .project-content {
        padding: 0 30px;
    }
}

@media (min-width: 600px) 
    and (max-width: 800px) {
    .project-content {
        padding: 0 50px;
    }
}

@media (min-width: 800px) {
    .project-content {
        padding: 0 100px;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/ProjectPages/projectpages.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,MAAM;IACN,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ;;;AAGA;;IAEI;QACI,eAAe;IACnB;AACJ;;AAEA;;IAEI;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".back {\n    color: #242424;\n    text-decoration: none;\n}\n\n.back:hover {\n    color: #717275;\n    font-weight: bolder;\n}\n\n.project-desc {\n    font-size: 1.25rem;\n}\n\n.project-title {\n    font-size: 2.5rem;\n}\n\n.project-image {\n    display: block;\n    margin-left: auto;\n    margin-right: auto;\n    max-width: 60%;\n}\n\n.project-link {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    display: flex;\n    top: 0;\n    z-index: 999;\n    padding: 0;\n}\n\n.project-additional {\n    text-align: center;\n}\n\n@media (max-width: 400px) {\n    .Home-Content {\n        padding: 0 10px;\n    }\n}\n\n\n@media (min-width: 400px) \n    and (max-width: 600px) {\n    .project-content {\n        padding: 0 30px;\n    }\n}\n\n@media (min-width: 600px) \n    and (max-width: 800px) {\n    .project-content {\n        padding: 0 50px;\n    }\n}\n\n@media (min-width: 800px) {\n    .project-content {\n        padding: 0 100px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
