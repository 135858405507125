// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sitemap-content {
    padding: 0 100px;
    font-family: "Raleway", "Lucida Sans", Arial, sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/pages/Sitemap/sitemap.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,wDAAwD;AAC5D","sourcesContent":[".sitemap-content {\n    padding: 0 100px;\n    font-family: \"Raleway\", \"Lucida Sans\", Arial, sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
