// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-element {
    grid-row: span 1;
    grid-column: span 1;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 10px;
    text-decoration: none;
    background-color: #c9c5c5;
}

.card-text-content {
    font-family: "Raleway", "Lucida Sans", Arial, sans-serif;
}

.card-link {
    color: black;
    text-decoration: none;
}

.card-title {
    margin-top: 3px;
    margin-bottom: 3px;
    text-align: center;
}

.card-text {
    margin: 5px;
    font-size: .8rem;
}

.card-image {
    object-fit: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.5%;
    border-radius: 10px;
    width: 95%;
}

.card-element:hover {
    box-shadow: 0 8px 16px 0 rgba(13, 13, 13, 0.2)
}

.card-element:hover .card-link .card-text-content {
    color: #717275;
}
`, "",{"version":3,"sources":["webpack://./src/components/ProjectCard/projectcard.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,uCAAuC;IACvC,gBAAgB;IAChB,mBAAmB;IACnB,qBAAqB;IACrB,yBAAyB;AAC7B;;AAEA;IACI,wDAAwD;AAC5D;;AAEA;IACI,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI;AACJ;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".card-element {\n    grid-row: span 1;\n    grid-column: span 1;\n    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);\n    transition: 0.3s;\n    border-radius: 10px;\n    text-decoration: none;\n    background-color: #c9c5c5;\n}\n\n.card-text-content {\n    font-family: \"Raleway\", \"Lucida Sans\", Arial, sans-serif;\n}\n\n.card-link {\n    color: black;\n    text-decoration: none;\n}\n\n.card-title {\n    margin-top: 3px;\n    margin-bottom: 3px;\n    text-align: center;\n}\n\n.card-text {\n    margin: 5px;\n    font-size: .8rem;\n}\n\n.card-image {\n    object-fit: cover;\n    display: block;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: 2.5%;\n    border-radius: 10px;\n    width: 95%;\n}\n\n.card-element:hover {\n    box-shadow: 0 8px 16px 0 rgba(13, 13, 13, 0.2)\n}\n\n.card-element:hover .card-link .card-text-content {\n    color: #717275;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
