// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  top: 0;
  z-index: 999;
  padding: 0;
}

.contact-heading {
  font-size: 1.2rem;
  margin: auto;
  text-align: center;
  padding-bottom: .5rem;
  padding-top: 2rem;
}
  
.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
  
.contact-menu {
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  list-style: none;
  padding-left: 0;
}
  
.contact-links {
  color: #000000;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0rem 1rem;
  font-family: "Raleway", "Lucida Sans", Arial, sans-serif;
}
  
.contact-links:hover {
  color: #717275;
  font-weight: bolder;
}

.contact-image {
  width: 1.5rem;
  padding-right: 5px;
}

.contact-links:hover .contact-image {
  filter: invert(47%) sepia(7%) saturate(151%) hue-rotate(187deg) brightness(92%) contrast(88%);
}`, "",{"version":3,"sources":["webpack://./src/components/Contact/contact.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,MAAM;EACN,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;EAClB,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;EACX,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,kBAAkB;EAClB,wDAAwD;AAC1D;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,6FAA6F;AAC/F","sourcesContent":[".contact {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 1.2rem;\n  top: 0;\n  z-index: 999;\n  padding: 0;\n}\n\n.contact-heading {\n  font-size: 1.2rem;\n  margin: auto;\n  text-align: center;\n  padding-bottom: .5rem;\n  padding-top: 2rem;\n}\n  \n.contact-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n  \n.contact-menu {\n  display: flex;\n  margin: 0;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  list-style: none;\n  padding-left: 0;\n}\n  \n.contact-links {\n  color: #000000;\n  display: flex;\n  align-items: center;\n  text-decoration: none;\n  padding: 0rem 1rem;\n  font-family: \"Raleway\", \"Lucida Sans\", Arial, sans-serif;\n}\n  \n.contact-links:hover {\n  color: #717275;\n  font-weight: bolder;\n}\n\n.contact-image {\n  width: 1.5rem;\n  padding-right: 5px;\n}\n\n.contact-links:hover .contact-image {\n  filter: invert(47%) sepia(7%) saturate(151%) hue-rotate(187deg) brightness(92%) contrast(88%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
