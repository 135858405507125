// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sitemap {
  font-size: 0.75rem;
  text-align: center;
  font-family: "Raleway", "Lucida Sans", Arial, sans-serif;
  padding: 0;
  margin-bottom: 10px;
}

.sitemap-link {
  color: #000000;
  text-align: center;
  text-decoration: none;
  height: 100%;
  font-family: "Raleway", "Lucida Sans", Arial, sans-serif;
}
    
.sitemap-link:hover {
  color: #3E424B;
  font-weight: bolder;
}`, "",{"version":3,"sources":["webpack://./src/components/Sitemap/sitemap.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,wDAAwD;EACxD,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,qBAAqB;EACrB,YAAY;EACZ,wDAAwD;AAC1D;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".sitemap {\n  font-size: 0.75rem;\n  text-align: center;\n  font-family: \"Raleway\", \"Lucida Sans\", Arial, sans-serif;\n  padding: 0;\n  margin-bottom: 10px;\n}\n\n.sitemap-link {\n  color: #000000;\n  text-align: center;\n  text-decoration: none;\n  height: 100%;\n  font-family: \"Raleway\", \"Lucida Sans\", Arial, sans-serif;\n}\n    \n.sitemap-link:hover {\n  color: #3E424B;\n  font-weight: bolder;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
