// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projects-content {
    font-family: "Raleway", "Lucida Sans", Arial, sans-serif;
}

.card-wrapper {
    position: relative;
    margin: 50px 0 50px;
}

.card-grid {
    display: grid;
    gap: 20px;
}

.card-grid {
    margin: 0 auto;
    max-width: 100%;
}

/*
.card-grid {
    margin: 0 auto;
    max-width: 100%;
    grid-template-columns: repeat(4, 1fr);
}
*/


@media (max-width: 450px) {

    .projects-content {
        padding: 0 10px;
    }

    .card-grid {
        grid-template-columns: 1fr;
    }
    
}

@media (min-width: 450px)
    and (max-width: 600px) {

    .projects-content {
        padding: 0 30px;
    }

    .card-grid {
        grid-template-columns: repeat(2, 1fr);
    }

}

@media (min-width: 600px)
    and (max-width: 800px) {
    
    .projects-content {
        padding: 0 50px;
    }

    .card-grid {

        grid-template-columns: repeat(3, 1fr);
    }
}


@media (min-width: 800px)
    and (max-width: 1500px) {
    .projects-content {
        padding: 0 100px;
    }
    .card-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 1500px) {
    .projects-content {
        padding: 0 100px;
    }
    .card-grid {
        grid-template-columns: repeat(4, 1fr);
    } 
}`, "",{"version":3,"sources":["webpack://./src/pages/Projects/projects.css"],"names":[],"mappings":"AAAA;IACI,wDAAwD;AAC5D;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;;;;;;CAMC;;;AAGD;;IAEI;QACI,eAAe;IACnB;;IAEA;QACI,0BAA0B;IAC9B;;AAEJ;;AAEA;;;IAGI;QACI,eAAe;IACnB;;IAEA;QACI,qCAAqC;IACzC;;AAEJ;;AAEA;;;IAGI;QACI,eAAe;IACnB;;IAEA;;QAEI,qCAAqC;IACzC;AACJ;;;AAGA;;IAEI;QACI,gBAAgB;IACpB;IACA;QACI,qCAAqC;IACzC;AACJ;;AAEA;IACI;QACI,gBAAgB;IACpB;IACA;QACI,qCAAqC;IACzC;AACJ","sourcesContent":[".projects-content {\n    font-family: \"Raleway\", \"Lucida Sans\", Arial, sans-serif;\n}\n\n.card-wrapper {\n    position: relative;\n    margin: 50px 0 50px;\n}\n\n.card-grid {\n    display: grid;\n    gap: 20px;\n}\n\n.card-grid {\n    margin: 0 auto;\n    max-width: 100%;\n}\n\n/*\n.card-grid {\n    margin: 0 auto;\n    max-width: 100%;\n    grid-template-columns: repeat(4, 1fr);\n}\n*/\n\n\n@media (max-width: 450px) {\n\n    .projects-content {\n        padding: 0 10px;\n    }\n\n    .card-grid {\n        grid-template-columns: 1fr;\n    }\n    \n}\n\n@media (min-width: 450px)\n    and (max-width: 600px) {\n\n    .projects-content {\n        padding: 0 30px;\n    }\n\n    .card-grid {\n        grid-template-columns: repeat(2, 1fr);\n    }\n\n}\n\n@media (min-width: 600px)\n    and (max-width: 800px) {\n    \n    .projects-content {\n        padding: 0 50px;\n    }\n\n    .card-grid {\n\n        grid-template-columns: repeat(3, 1fr);\n    }\n}\n\n\n@media (min-width: 800px)\n    and (max-width: 1500px) {\n    .projects-content {\n        padding: 0 100px;\n    }\n    .card-grid {\n        grid-template-columns: repeat(3, 1fr);\n    }\n}\n\n@media (min-width: 1500px) {\n    .projects-content {\n        padding: 0 100px;\n    }\n    .card-grid {\n        grid-template-columns: repeat(4, 1fr);\n    } \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
