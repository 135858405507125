// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copyright {
    text-align: center;
    font-size: 0.75rem;
    font-family: "Raleway", "Lucida Sans", Arial, sans-serif;
    margin: auto 0;
    display: inline;
}
`, "",{"version":3,"sources":["webpack://./src/components/Copyright/copyright.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,wDAAwD;IACxD,cAAc;IACd,eAAe;AACnB","sourcesContent":[".copyright {\n    text-align: center;\n    font-size: 0.75rem;\n    font-family: \"Raleway\", \"Lucida Sans\", Arial, sans-serif;\n    margin: auto 0;\n    display: inline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
