// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home-Content {
    font-family: "Raleway", "Lucida Sans", Arial, sans-serif;
}

.hello {
    font-size: 3rem;
}

.main-text {
    font-size: 1.5rem;
}

.below-text {
    font-size: 1.25rem;
}

.link {
    text-decoration: none;
    color: #006aff;
    font-weight: bold;
}

.link:hover {
    color: #1dc2e7;
}

@media (max-width: 400px) {
    .Home-Content {
        padding: 0 10px;
    }
}


@media (min-width: 400px) 
    and (max-width: 600px) {
    .Home-Content {
        padding: 0 30px;
    }
}

@media (min-width: 600px) 
    and (max-width: 800px) {
    .Home-Content {
        padding: 0 50px;
    }
}

@media (min-width: 800px) {
    .Home-Content {
        padding: 0 100px;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/home.css"],"names":[],"mappings":"AAAA;IACI,wDAAwD;AAC5D;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ;;;AAGA;;IAEI;QACI,eAAe;IACnB;AACJ;;AAEA;;IAEI;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".Home-Content {\n    font-family: \"Raleway\", \"Lucida Sans\", Arial, sans-serif;\n}\n\n.hello {\n    font-size: 3rem;\n}\n\n.main-text {\n    font-size: 1.5rem;\n}\n\n.below-text {\n    font-size: 1.25rem;\n}\n\n.link {\n    text-decoration: none;\n    color: #006aff;\n    font-weight: bold;\n}\n\n.link:hover {\n    color: #1dc2e7;\n}\n\n@media (max-width: 400px) {\n    .Home-Content {\n        padding: 0 10px;\n    }\n}\n\n\n@media (min-width: 400px) \n    and (max-width: 600px) {\n    .Home-Content {\n        padding: 0 30px;\n    }\n}\n\n@media (min-width: 600px) \n    and (max-width: 800px) {\n    .Home-Content {\n        padding: 0 50px;\n    }\n}\n\n@media (min-width: 800px) {\n    .Home-Content {\n        padding: 0 100px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
